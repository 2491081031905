@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?qcidps') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?qcidps') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?qcidps##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle; 
  }
}
.icon-visit {
  &:before {
    content: $icon-visit; 
  }
}
.icon-pay {
  &:before {
    content: $icon-pay; 
  }
}
.icon-happy_birthday1 {
  &:before {
    content: $icon-happy_birthday1; 
  }
}
.icon-baptism-icon2 {
  &:before {
    content: $icon-baptism-icon2; 
  }
}
.icon-plans-subscriptions {
  &:before {
    content: $icon-plans-subscriptions; 
  }
}
.icon-wedding-anniversary {
  &:before {
    content: $icon-wedding-anniversary; 
  }
}
.icon-baloon {
  &:before {
    content: $icon-baloon; 
  }
}
.icon-other {
  &:before {
    content: $icon-other; 
  }
}
.icon-corporate {
  &:before {
    content: $icon-corporate; 
  }
}
.icon-gender-reveal {
  &:before {
    content: $icon-gender-reveal; 
  }
}
.icon-happy_birthday {
  &:before {
    content: $icon-happy_birthday; 
  }
}
.icon-festaza-star {
  &:before {
    content: $icon-festaza-star; 
  }
}
.icon-baptism {
  &:before {
    content: $icon-baptism; 
  }
}
.icon-help-circle {
  &:before {
    content: $icon-help-circle; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-key {
  &:before {
    content: $icon-key; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-smile {
  &:before {
    content: $icon-smile; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-x-circle {
  &:before {
    content: $icon-x-circle; 
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-up {
  &:before {
    content: $icon-up; 
  }
}
.icon-right {
  &:before {
    content: $icon-right; 
  }
}
.icon-left {
  &:before {
    content: $icon-left; 
  }
}
.icon-down {
  &:before {
    content: $icon-down; 
  }
}
.icon-x-octagon {
  &:before {
    content: $icon-x-octagon; 
  }
}
.icon-wedding {
  &:before {
    content: $icon-wedding; 
  }
}

