@use './theming.scss';
@use './media-queries.scss';
@use './typography.scss';
@use './z-index.scss';
@use './constants.scss';
@use './animation.scss';
@use './toaster.scss';
@use './manrope.scss';
// @use 'bootstrap/scss/bootstrap';
@import './assets/fonts/icons/style.scss';
@import '@angular/cdk/overlay-prebuilt.css';

* {
  touch-action: manipulation;
}

html,
body,
bk-root {
  overflow: hidden;
  background-color: var(--bs-surface-1);
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;

  &,
  button,
  select,
  bk-date-picker>:is(label, input) {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 500;
    color: var(--bs-body-color);

    &.is-disabled {
      color: var(--bs-default-lighter);
      cursor: not-allowed;
    }
  }
}

router-outlet {
  display: none;
}

body:has(bk-http-processing-loader.visible, bk-media-carousel, .cdk-global-overlay-wrapper) {
  overflow: hidden;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(5.1px);
}

a {
  color: inherit;
  text-decoration: none;
}

.unselectable {
  user-select: none;
}

a,
button {
  @extend .unselectable;
  cursor: pointer;
  border: none;
  background: unset;
  padding: 0;
  text-align: start;

  &:is([disabled="true"]) {
    cursor: default;
  }

  &.nav {
    padding: 10px;
    border: none;
    background-color: var(--bs-primary);
    border-bottom: 3px solid #aaf;

    &:is([disabled="true"]) {
      background-color: #eef;
    }
  }
}

.primary-button {
  border: 1px solid hsl(var(--primary-color), 100%, calc(var(--global-luminosity) * 50%));
  background-color: var(--bs-primary);
  padding: 10px;
}

.btn-padding {
  padding: 16px 28px;
}

:is(button, a, bk-file-upload).btn {
  font-size: 18px;
  padding: var(--bs-btn-padding-x) var(--bs-btn-padding-y);
  font-weight: 600;
  border: 2px solid;
  border-radius: constants.$btn-border-radius;
}

.cursor-forbidden {
  cursor: not-allowed;
}

.vertical-align-middle {
  vertical-align: middle;
}

.is-selecting {
  position: relative;
  animation: scale-down .15s linear forwards;

  &::before {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 5px;
    right: 5px;
    border-radius: 50vw;
    z-index: 1;
    background-color: var(--bs-surface-1);
  }
}

.is-selected {
  color: hsl(var(--secondary-hue), var(--secondary-saturation), var(--secondary-lightness));
  animation: scale-up .15s linear forwards;
}

.overflow-auto {
  overflow: auto;
}

.flex-0 {
  flex: 1 1 0;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(233, 155, 150, .5);
}
