@use 'sass:math';
@use './colors.scss' as col;
@use './constants.scss' as constants;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$btn-padding-y: 1.375rem;
$border-radius: 0.75rem;
$card-spacer-y: 50px;
$btn-color: col.$primary;
$modal-border-radius: 1.5rem;
$modal-header-padding: 1rem 0rem;
$border-color: col.$default-extra-lighter;

$theme-colors: map-merge($theme-colors,
    ('primary': col.$primary,
      'secondary': col.$secondary,
      'secondary-extra-lighter': col.$secondary-extra-lighter,
      'surface-1': col.$surface-1,
      'surface-2': col.$surface-2,
      'body-color': col.$body-color,
      'default-lighter': col.$default-lighter,
      'default-extra-lighter': col.$default-extra-lighter,
      'decoration-1': col.$decoration-1,
      'danger':col.$danger,
    ));

.bg-glass {
  background: rgba(255, 255, 255, 0.1);
  background: linear-gradient(144deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.16) 23%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(13.1px);
  border-width: 1px;
}

@import 'bootstrap/scss/bootstrap';

.modal {
  --bs-modal-border-radius: #{$modal-border-radius};

  @include media-breakpoint-down(sm) {
    --bs-modal-border-radius: 0;
    --bs-modal-margin: 0%;
    --bs-modal-border-width: 0;
  }
}

.modal-content.modal-content {
  padding-block: 40px;
  padding-inline: constants.$app-padding-inline;

  @include media-breakpoint-down(sm) {
    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;
    min-height: 60vh;
    max-height: calc(100% - 50px);
  }
}

@mixin set-button-color($name) {

  .btn-#{$name},
  .btn-#{$name}:hover,
  .btn-#{$name}:active {
    --bs-btn-disabled-color: #{col.$surface-1};
    --bs-btn-color: #{col.$surface-1};
    --bs-btn-hover-color: #{col.$surface-1};
    --bs-btn-active-color: #{col.$surface-1};
  }
}

@include set-button-color('primary');
@include set-button-color('secondary');
@include set-button-color('outline-primary');

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  --bs-btn-disabled-color: #{col.$surface-1};
  --bs-btn-color: #{col.$surface-1};
  --bs-btn-hover-color: #{col.$surface-1};
  --bs-btn-active-color: #{col.$surface-1};
}

.btn-outline-primary {
  @include button-outline-variant(col.$primary, #222222, lighten(col.$primary, 5%), col.$primary);
}
