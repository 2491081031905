.cdk-overlay-pane {
  animation: slide-up 0.1s forwards;

}

@keyframes slide-up {
  0% {
    transform: translateY(100vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}


@keyframes scale-down {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}
