h1 {
  margin: 0;
}

.extra-larger {
  font-size: 24px;
}

.smallish {
  font-size: 16px;
}

.d-font-weight {
  font-weight: 500;
}

.smaller {
  font-size: 14px;
}

.extra-smaller {
  font-size: 12px;
}

.boldest {
  font-weight: 900;
}
