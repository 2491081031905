.background-image {
  z-index: -500;
}

bk-main-menu {
  z-index: 999;
}

// CDK OVERLAY --- 1000

bk-http-processing-loader {
  z-index: 1100;
}

bk-media-carousel {
  z-index: 2000;
}
