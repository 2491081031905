.toast-warning.toast-warning.toast-warning.toast-warning {
  background-color: var(--bs-surface-1);
  background-image: url('assets/icons/icon-128x128.png');
  border-radius: 10px;
  margin-top: 10px;
  width: min(90%, 900px);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #D0D5DD;
  color: var(--bs-body-color);

  .toast-close-button {
    color: var(--bs-default-lighter);
  }
}

.ngx-toastr.ngx-toastr.ngx-toastr {
  box-shadow: 0px 12px 16px -4px hsla(220, 43%, 11%, 8%),
    0px 6px 4px -2px hsla(220, 43%, 11%, 3%);
}
