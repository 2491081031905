$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-circle: unquote('"\\e925"');
$icon-check-circle: unquote('"\\e926"');
$icon-visit: unquote('"\\e924"');
$icon-pay: unquote('"\\e923"');
$icon-happy_birthday1: unquote('"\\e922"');
$icon-baptism-icon2: unquote('"\\e921"');
$icon-plans-subscriptions: unquote('"\\e91d"');
$icon-wedding-anniversary: unquote('"\\e91b"');
$icon-baloon: unquote('"\\e920"');
$icon-other: unquote('"\\e91f"');
$icon-corporate: unquote('"\\e91e"');
$icon-gender-reveal: unquote('"\\e91c"');
$icon-happy_birthday: unquote('"\\e91a"');
$icon-festaza-star: unquote('"\\e919"');
$icon-baptism: unquote('"\\e900"');
$icon-help-circle: unquote('"\\e901"');
$icon-download: unquote('"\\e902"');
$icon-key: unquote('"\\e903"');
$icon-clock: unquote('"\\e904"');
$icon-eye: unquote('"\\e905"');
$icon-folder: unquote('"\\e906"');
$icon-smile: unquote('"\\e907"');
$icon-code: unquote('"\\e908"');
$icon-x-circle: unquote('"\\e909"');
$icon-navigation: unquote('"\\e90a"');
$icon-upload: unquote('"\\e90b"');
$icon-plus: unquote('"\\e90c"');
$icon-image: unquote('"\\e90d"');
$icon-user: unquote('"\\e90e"');
$icon-log-out: unquote('"\\e90f"');
$icon-calendar: unquote('"\\e910"');
$icon-list: unquote('"\\e911"');
$icon-edit: unquote('"\\e912"');
$icon-up: unquote('"\\e913"');
$icon-right: unquote('"\\e914"');
$icon-left: unquote('"\\e915"');
$icon-down: unquote('"\\e916"');
$icon-x-octagon: unquote('"\\e917"');
$icon-wedding: unquote('"\\e918"');

