@use './media-queries.scss' as mq;

:root {
  --app-padding-block: 20px;
  --app-padding-inline: 20px;
  --app-bottom-margin: 80px;
}

@include mq.desktop {
  :root {
    --app-padding-block: 40px;
    --app-padding-inline: 40px;
  }
}

$app-padding-block: var(--app-padding-block);
$app-padding-inline: var(--app-padding-inline);
$app-padding: $app-padding-block $app-padding-inline;
$app-bottom-margin: var(--app-bottom-margin);
$menu-size: 402.34px;
$btn-border-radius: 5px;
