@mixin mobile {
  @media (max-width: 767.9px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1023.9px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

.mobile,
.tablet,
.desktop {
  display: none;
}

@include mobile() {
  .mobile {
    display: flex;
  }
}

@include tablet() {
  .tablet {
    display: flex;
  }
}

@include desktop() {
  .desktop {
    display: flex;
  }
}
